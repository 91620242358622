<template>
  <div class="login-container kyc-container">
    <div :class="`container-login100 ${!isMobile ? 'bg-slide' : ''}`">
      <span class="mask bg-gradient-primary opacity-6"></span>
      <div class="wrap-login100">
        <div class="login100-pic">
          <img :src="'/img/theme/kyc-wallet.png'" alt="IMG" />
        </div>

        <el-form>
          <span class="login100-form-title"> Staff login </span>
          <img
            :src="'/img/theme/sticker-wait.png'"
            style="width: 100%"
            title="qrcode"
            alt="img"
          />
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from "@/utils/helpers";
import { loginWalletAsStaff } from "@/api/user";
import CustomerApi from "@/api/customer";
export default {
  computed: {
    isMobile() {
      return isMobile();
    },
  },
  methods: {
    login() {
      loginWalletAsStaff(this.$route.params.token).then(
        ({ result, data, message }) => {
          if (result) {
            this.$store.dispatch("user/login", data).then(() => {
              this.getAccount(data.token, () => {
                setTimeout(() => {
                  this.$router.push({ path: "/wallet/dashboard" });
                }, 1500);
              });
            });
          } else {
            this.$swal("Message", message, "error").then(() => {
              this.$router.push({ path: "/login" });
            });
          }
        }
      );
    },
    getAccount(token, cb) {
      CustomerApi.account().then(({ result, data }) => {
        if (result) {
          data["token"] = token;
          this.$store.dispatch("user/login", data).then(() => {
            cb();
          });
        }
      });
    },
  },
  mounted() {
    const hasToken = this.$store.getters.token;
    if (hasToken) {
      this.$store.dispatch("user/logout").then(() => {
        localStorage.removeItem("vuex");
        this.login();
      });
    } else {
      localStorage.removeItem("vuex");
      this.login();
    }
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/login.scss";

.kyc-container {
  .el-form {
    width: 300px;
    padding: 50px 34px 50px 0;
  }
  .el-form-item__content {
    line-height: 1.5;
  }
  .text-message {
    line-height: 1.5 !important;
  }
}
</style>
